<template>
  <div class="p-6">
    <a-form layout="inline" :form="form" class="form-label-4">
      <a-form-item label="日期">
        <a-range-picker
          format="YYYY-MM"
          :value="month"
          :mode="mode"
          @panelChange="panelChange"
          @change="monthChange"
          @openChange="openChange"
        />
      </a-form-item>
      <a-form-item label="项目">
        <a-select-pro
          class="form-control"
          v-decorator="['projectCode']"
          placeholder="请选择"
          allowClear
          showSearch
          :options="projectList"
          @change="doQuery(1)"
          :dropdownMatchSelectWidth="false"
        ></a-select-pro>
      </a-form-item>
      <a-form-item label="班组">
        <a-select-pro
          class="form-control"
          v-decorator="['leaderId']"
          placeholder="请选择"
          allowClear
          showSearch
          :options="leaderList"
          @change="doQuery(1)"
        ></a-select-pro>
      </a-form-item>
    </a-form>

    <br />
    <span  style="color: black">
       工人姓名：{{this.userName}}
      &emsp;&emsp;&emsp;
       身份证号：{{this.cardId}}
 &emsp;&emsp;&emsp; &emsp;&emsp;&emsp;
    <span style="color: red;">特别说明:本表中本月实付金额为工资表编制流程已归档的数据，支付状态为成功时才表示已支付工人工资成功。</span>
    </span>
    <br />


    <div class="mt-6">
      <a-table
        rowKey="id"
        bordered
        :loading="loading"
        :columns="tableColumns"
        :dataSource="dataSource"
        :pagination="false"
        :scroll="{
          x: 1590,
        }"
        size="small"
      ></a-table>
      <div class="flex text-lg">
        <div style="width: 80px; text-align: center">汇总</div>
        <!-- <div>
          应发考勤天数:
          <span class="text-black">{{ attendanceDaysSum }}</span>
        </div> -->
        <div class="flex flex-wrap">
          <div class="ml-10">
            实际发放工资天数:
            <span class="text-black">{{ actualAttendanceDaysSum }}</span>
          </div>
           <div class="ml-10">
            应发金额/元:
            <span class="text-black">{{ needPayAmountSum }}</span>
          </div>
          <div class="ml-10">
            工人借支金额/元:
            <span class="text-black">{{ repayAmountSum }}</span>
          </div>
          <div class="ml-10">
            实付/元:
            <span class="text-black">{{ actualPayAmountSum }}</span>
          </div>
<!--           <div class="ml-10">
            欠付/元:
            <span class="text-black">{{ unpaidAmountSum }}</span>
          </div>
          <div class="ml-10">
            已支付/核销欠付金额/元:
            <span class="text-black">{{ offAmountSum }}</span>
          </div>-->
          <div class="ml-10">
            剩余欠付金额/元:
            <span class="text-black">{{ hasAmountSum }}</span>
          </div>
        </div>
      </div>
      <base-pagination
        class="mt-6"
        :totalCount.sync="totalCount"
        :pageNo.sync="pageNo"
        :pageSize.sync="pageSize"
        @change="pageChange"
        size="small"
      />
    </div>
    <OaDocModal ref="oaDocModal"></OaDocModal>
  </div>
</template>

<script>
import { getProjectListByCard, getLeaderListByCard } from '@/service/getData'
import { queryViewMixin } from '@/utils/mixin'

import ASelectPro from '@/components/SelectPro.vue'
import OaDocModal from './OaDocModal.vue'

export default {
  components: {
    ASelectPro,
    OaDocModal,
  },
  mixins: [queryViewMixin],
  data() {
    const tableColumns = [
      {
        title: '工资月份',
        width: 80,
        align: 'center',
        dataIndex: 'salaryMonth',
        fixed: 'left',
      },
      {
        title: '项目',
        width: '8%',
        align: 'left',
        dataIndex: 'projectName',
      },
      {
        title: '班组',
        width: '5%',
        align: 'center',
        dataIndex: 'leaderName',
      },
      {
        title: '工资单号',
        width: '5%',
        align: 'left',
        dataIndex: 'salaryCode',
      },
      {
        title: '工资单发放通道',
        width: '5%',
        align: 'center',
        dataIndex: 'salaryChannel',
      },
      {
        title: '工资单类型',
        width: '5%',
        align: 'center',
        dataIndex: 'salaryType',
      },
      {
        title: '应发考勤天数',
        width: '5%',
        align: 'center',
        dataIndex: 'attendanceDays',
      },
      {
        title: '实际发放工资天数',
        width: '5%',
        align: 'center',
        dataIndex: 'actualAttendanceDays',
      },
       {
        title: '应发金额/元',
        width: '5%',
        align: 'center',
        dataIndex: 'needPayAmount',
      },
      {
        title: '偿还班组借支金额/元',
        width: '5%',
        align: 'center',
        dataIndex: 'repayAmount',
      },
      {
        title: '本月实付/元',
        width: '5%',
        align: 'center',
        dataIndex: 'actualPayAmount',
      },
       {
        title: '本月欠付/元',
        width: '5%',
        align: 'center',
        dataIndex: 'unpaidAmount',
      },
      {
        title: '支付单号',
        width: '5%',
        align: 'left',
        dataIndex: 'paymentCode',
      },
      {
        title: '支付账号',
        width: '8%',
        align: 'left',
        dataIndex: 'paymentAcoount',
      },
      {
        title: '支付状态',
        width: '5%',
        align: 'center',
        dataIndex: 'paymentStatus',
      },
      {
        title: '支付日期',
        width: '6%',
        align: 'left',
        dataIndex: 'paymentDate',
      },
      {
        title: '支付流水号',
        width: '5%',
        align: 'center',
        dataIndex: 'paymentNum',
      },
      {
        title: '银行回单号',
        width: '5%',
        align: 'center',
        dataIndex: 'bankReceiptNum',
      },
      {
        title: '操作',
        width: 120,
        align: 'center',
        customRender: (text, row) => {
          return (
            <div>
              <div>
                {row.originCertificate ? (
                  <a
                    onClick={() => {
                      this.checkCert(row)
                    }}
                  >
                    查看原始凭证
                  </a>
                ) : (
                  <span class="text-gray-400">查看原始凭证</span>
                )}
              </div>
              <div>
                {row.receiptFileList.length ? (
                  <a
                    onClick={() => {
                      this.checkReceipt(row)
                    }}
                  >
                    查看收条
                  </a>
                ) : (
                  <span class="text-gray-400">查看收条</span>
                )}
              </div>
              <div>
                {row.promiseBookFileList.length ? (
                  <a
                    onClick={() => {
                      this.checkCommitment(row)
                    }}
                  >
                    查看班组承诺书
                  </a>
                ) : (
                  <span class="text-gray-400">查看班组承诺书</span>
                )}
              </div>
            </div>
          )
        },
      },
    ]
    return {
      form: this.$form.createForm(this),
      pageNo: 1,
      pageSize: 10,
      totalCount: 0,
      loading: false,
      tableColumns: tableColumns,
      dataSource: [],

      projectList: [],
      leaderList: [],
      month: [],
      mode: ['month', 'month'],

      attendanceDaysSum: 0,
      actualAttendanceDaysSum: 0,
      needPayAmountSum: 0,
      repayAmountSum: 0,
      actualPayAmountSum: 0,
      unpaidAmountSum: 0,
      offAmountSum: 0,
      hasAmountSum: 0,

      cardId:"",
      userName:"",
    }
  },
  methods: {
    async doQuery(_pageNo = this.pageNo, _pageSize = this.pageSize) {
      this.loading = true
      try {
        await this.$nextTick()
        const params = {
          ...this.getPageParams(_pageNo, _pageSize),
          cardId: this.$route.query.cardId,
          month: this.month,
        }

        const res = await this.$axios.post(
          '/report/evidence/chain/salary/list',
          this.formatTimeRangeParams(params, 'month', { format: 'YYYY-MM' })
        )
        const { pageSize, pageNo, totalCount, list } = res.data
        this.totalCount = totalCount
        this.pageSize = pageSize
        this.pageNo = pageNo
        this.dataSource = list
        this.attendanceDaysSum = res.data.attendanceDaysSum
        this.actualAttendanceDaysSum = res.data.actualAttendanceDaysSum
        this.needPayAmountSum = res.data.needPayAmountSum
        this.repayAmountSum = res.data.repayAmountSum
        this.actualPayAmountSum = res.data.actualPayAmountSum
        this.unpaidAmountSum = res.data.unpaidAmountSum
        this.offAmountSum = res.data.offAmountSum
        this.hasAmountSum = res.data.hasAmountSum
      } catch (e) {
        console.log(e)
      }
      this.loading = false
    },
    panelChange(month, mode) {
      this.month = month
      this.mode = [mode[0] === 'date' ? 'month' : mode[0], mode[1] === 'date' ? 'month' : mode[1]]
    },
    monthChange(month) {
      this.month = month
      this.doQuery(1)
    },
    openChange(open) {
      if (!open) {
        this.doQuery(1)
      }
    },
    async openOssUrl(ossUrl) {
      const res = await this.$axios.post('/report/evidence/chain/tmp/pathUrl', {
        ossUrl,
      })
      const url = res.data
      window.open(url)
    },
    checkCert(row) {
      this.openOssUrl(row.originCertificate)
    },
    checkReceipt(row) {
      this.$refs.oaDocModal.start(row.receiptFileList)
    },
    checkCommitment(row) {
      this.$refs.oaDocModal.start(row.promiseBookFileList)
    },
  },
  async mounted() {
    const { cardId, projectCode, leaderId } = this.$route.query
    const param={ cardId, projectCode, leaderId }
    const res = await this.$axios.post(`/report/evidence/chain/worker/Info`, param)
    this.userName=res.data.username
    this.cardId=cardId

    this.projectList = await getProjectListByCard({ cardId })
    this.leaderList = await getLeaderListByCard({ cardId })
    this.doQuery()
  },
}
</script>

<style lang="less" scoped></style>
